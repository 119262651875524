<template>
  <transition name="fade">
    <div class="story-item-page main" :key="$route.params.item_id">
      <ItemTemplate
        :data="story"
        category="story"
        :maxItemID="maxItemID"
      ></ItemTemplate>
      <div class="ss-container">
        <div class="hr"></div>
      </div>
      <div class="bg-black black-section">
        <ListTemplate :data="otherProjects" category="story"></ListTemplate>
      </div>
    </div>
  </transition>
</template>

<script>
import ItemTemplate from "@/components/ItemTemplate.vue";
import ListTemplate from "@/components/ListTemplate.vue";
import Stories from "@/data/stories.js";
export default {
  name: "StoryItem",
  components: {
    ItemTemplate,
    ListTemplate
  },
  data() {
    return {
      stories: Stories
    };
  },
  watch: {
    story: function() {
      if (this.story.length === 0) {
        this.$router.push({ name: "Error404" });
      }
    }
  },
  mounted() {
    if (this.story.length == 0) {
      this.$router.push({ name: "Error404" });
    }
  },
  computed: {
    story() {
      var _this = this;
      return this.stories.filter(function(item) {
        return item.id === parseInt(_this.$route.params.item_id);
      });
    },
    maxItemID() {
      return Math.max(
        ...this.stories.map(function(item) {
          return item.id;
        })
      );
    },
    otherProjects() {
      var otherProjectsSet = new Set();
      var _this = this;
      if (
        // 如果圖大於11張則隨機挑十張（排除自己）
        this.stories.length > 11
      ) {
        while (otherProjectsSet.size < 10) {
          otherProjectsSet.add(
            this.getRandomItem(
              this.stories.filter(function(item) {
                return item.id !== parseInt(_this.$route.params.item_id);
              })
            )
          );
        }
        return [...otherProjectsSet];
      } else {
        // 如果數量不夠的話就把自己以外的全放出來
        return this.stories.filter(function(item) {
          return item.id !== parseInt(_this.$route.params.item_id);
        });
      }
    }
  },
  methods: {
    getRandomItem(arr) {
      // get random index value
      const randomIndex = Math.floor(Math.random() * arr.length);
      // get random item
      const item = arr[randomIndex];
      return item;
    }
  }
};
</script>

<style lang="sass">
.story-item-page
  padding-top: 106px
  background-color: #111111
  +deviceWidth()
    padding-top: 50px
  .item-image
    max-width: 480px
  .item-content
    h4, p
      color: #fafafa
  .black-section
    padding-bottom: 48px
    +deviceWidth()
      padding-bottom: 30px
</style>
